<style scoped>
.table {
  color: #fff;
}
.td-draggable {
  cursor: grab;
}
.td-draggable:hover {
  background-color: #757e8a55;
}
.no-line,
.no-line td {
  border-top: 0px solid #35393e;
}
button.disabled:hover {
  background-color: unset;
  cursor: pointer;
}
.quotes-list-move {
  transition: transform 0.2s;
}
.slide-fade-enter-active {
  transition: opacity 0.3s ease;
}
.slide-fade-leave-active {
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.drop-pallete {
  padding-top: 2px;
  padding-right: 12px;
  padding-bottom: 2px;
  padding-left: 12px;
  /* width: auto; */
  margin: 4px;
  -webkit-transition: 0.15s linear;
  transition: 0.15s linear;
  /* background-color: green; */
}
.drop-pallete .item {
  /* background-color: orange;
  color: orange; */
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  width: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.drop-pallete .item:hover {
  border: 4px solid;
  padding-left: 6px;
  padding-right: 6px;
}
/* Colores para los renglones de cotizaciones*/
.summary-row-default {
  background-color: #575e6133;
  color: #575e61;
}
.summary-row-block1 {
  background-color: #ececec14;
  color: white;
}
.summary-row-block2 {
  background-color: #f4fde133;
  color: #f4fde1;
}
.summary-row-info {
  background-color: #0062912e;
  color: #a1defa;
}
.summary-row-warning {
  background-color: #deb86333;
  color: #fffd71;
}
.paste-quotation-input {
  border: 1px solid #926dde;
  margin: 4px;
}
</style>
<template>
  <div class="box" :class="{'box-outline-warning': unsaved, 'box-fullscreen': isfullscreen}">
    <div class="box-header d-print-none">
      <transition name="slide-fade">
        <span :key="quotation.quote_sequence">
          <h3 class="box-title">
            SO{{ quotation.quote_sequence }}
          </h3>
        </span>
      </transition>
      <div class="box-controls float-right">
        <div class="box-header-actions">
          <div class="dropdown mx-0">
            <button
              id="dropdownMenuButton" class="hideToSecondaryUsers btn btn-sm btn-default dropdown-toggle pull-right" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              style=""
            >
              <Icon class="text-white" icon="lucide:copy-plus" />
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item clone-quotation-btn" href="#" @click="copyToClipboard($event, 1.0, -1)">
                Copiar </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item clone-quotation-btn" href="#" @click="copyToClipboard($event, 1.10, -1)">
                Copiar +10%
              </a>
              <a class="dropdown-item clone-quotation-btn" href="#" @click="copyToClipboard($event, 1.15, -1)">
                Copiar +15%
              </a>
              <a class="dropdown-item clone-quotation-btn" href="#" @click="copyToClipboard($event, 1.20, -1)">
                Copiar +20%
              </a>
              <a class="dropdown-item clone-quotation-btn" href="#" @click="copyToClipboard($event, 1.25, -1)">
                Copiar +25%
              </a>
              <div class="paste-quotation-input" @paste="onPaste">
                <input
                  ref="pasteField"
                  class="form-control form-control-sm"
                  type="text"
                  value=""
                  placeholder="Pega aquí"
                  @keypress.prevent
                >
              </div>
            </div>
          </div>

          <div class="dropdown mx-0">
            <button
              id="dropdownMenuButton" class="hideToSecondaryUsers btn btn-sm btn-default dropdown-toggle pull-right" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              style=""
            >
              <span class="hidden-sm-up">
                <Icon class="text-white" :icon="statusIcons" />

              </span>

              <span class="hidden-xs-down">
                {{ statusTitle }}
              </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item markinprogress-quotation-btn" href="#" @click.prevent="setQuotationStatus('open')">
                <Icon class="text-white" icon="carbon:edit" />
                Editable
              </a>
              <a class="dropdown-item marklocked-quotation-btn" href="#" @click.prevent="setQuotationStatus('locked')">
                <Icon class="text-white" icon="ant-design:lock-filled" />
                Bloqueada
              </a>
              <a class="dropdown-item markaccepted-quotation-btn" href="#" @click.prevent="setQuotationStatus('accepted')">
                <Icon class="text-white" icon="mdi:thumbs-up" />
                Aceptada
              </a>
              <a class="dropdown-item marktrash-quotation-btn" href="#" @click.prevent="setQuotationStatus('trash')">
                <Icon class="text-white" icon="tabler:trash" />
                Cancelada
              </a>
              <!-- <div class="dropdown-divider"></div>
            <a class="dropdown-item markcloned-quotation-btn" href="#">
              <i class="fa fa-fw fa-magic"></i> Recalcular clon
            </a> -->
            </div>
          </div>

          <div class="btn-group float-right contain-dropdown-brands mx-0">
            <button type="button" class="btn btn-sm btn-info" data-brand="edis" @click="generatePdf">
              <span class="hidden-sm-up">
                <svg
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
                  style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"
                >
                  <path d="M5.8 14H5v1h.8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM11 2H3v16h13V7l-5-5zM7.2 14.6c0 .8-.6 1.4-1.4 1.4H5v1H4v-4h1.8c.8 0 1.4.6 1.4 1.4v.2zm4.1.5c0 1-.8 1.9-1.9 1.9H8v-4h1.4c1 0 1.9.8 1.9 1.9v.2zM15 14h-2v1h1.5v1H13v1h-1v-4h3v1zm0-2H4V3h7v4h4v5zm-5.6 2H9v2h.4c.6 0 1-.4 1-1s-.5-1-1-1z" fill-rule="evenodd" clip-rule="evenodd" fill="white" />
                </svg>
              </span>
              <span class="hidden-xs-down">
                Guardar y crear PDF
              </span>
            </button>
            <button type="button" class="btn btn-sm btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
              <span class="caret"></span>
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(66px, 37px, 0px); top: 0px; left: 0px; will-change: transform;">
              <div class="dropdown-header">
                Membrete especial...
              </div>
              <a class="dropdown-item" data-brand="edis" href="javascript:void(0)">EDIS Interactive</a>
              <!-- <a class="dropdown-item print-quotation-btn" data-brand="caraquita" href="javascript:void(0)">Caraquita Games</a>
                          <a class="dropdown-item print-quotation-btn" data-brand="desarrollos" href="javascript:void(0)">Desarrollos NRG</a> -->
              <a class="dropdown-item" data-brand="nrg" href="javascript:void(0)">DNRG</a>
            </div>
          </div>
          <div class="btn-group mx-0">
            <button
              type="button" class="btn btn-sm btn-default dropdown-toggle" title="Compartir"
              data-toggle="dropdown" :class="{ 'text-warning': shareCount > 0 }"
            >
              <Icon v-if="shareCount > 0" icon="fa-solid:users" />
              <Icon v-else icon="fa-solid:user" />
            </button>
            <div class="dropdown dropdown-menu dropdown-sm">
              <h6 class="dropdown-header ">
                <span class="badge badge-pill badge-primary">Compartir con:</span>
              </h6>
              <a
                v-for="item in sharedUsers" :key="item.id" class="dropdown-item"
                :class="[item.sharing ? 'text-primary' : 'text-default']" href="#" @click.prevent="toggleSharing(item)"
              >
                <svg
                  v-if="item.sharing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true" role="img" style="vertical-align: -0.125em;" width="1em" height="1em"
                  preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"
                >
                  <g fill="currentColor">
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </g>
                </svg>
                {{ item.email }}
              </a>
            </div>
          </div>
          <button
            type="button" class="btn btn-sm btn-default" href="#"
            @click="isfullscreen = !isfullscreen"
          >
            <Icon icon="bx:fullscreen" />
          </button>
        </div>
      </div>
    </div>
    <div class="box-body">
      <ul class="nav nav-tabs" style="display: none;" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#home2" role="tab">
            <span class="hidden-sm-up"><i class="ion-home"></i></span>
            <span class="hidden-xs-down">
              SO{{ quotation.quote_sequence }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#profile2" role="tab">
            <span class="hidden-sm-up"><i class="ion-person"></i></span>
            <span class="hidden-xs-down">
              Facturas
              <span data-toggle="tooltip" title="" class="badge badge-pill bg-blue " data-original-title="1 New Messages">1</span>
            </span>
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div id="home2" class="tab-pane active" role="tabpanel" aria-expanded="true">
          <div class="pad">
            <div ref="printContainer">
              <div class="table-responsive">
                <table class="table table-condensed ">
                  <tbody>
                    <tr class="no-line" style="">
                      <td class="no-line">
                        <strong>
                          Facturar a:
                        </strong>
                        <address>
                          <span class="contact-name text-bold">
                            <LiveSearch
                              :value="contact.name"
                              :livesearch-values="searchresults"
                              empty="(Cotización borrador)"
                              :enabled="isEditable"
                              @searchValueChanged="onContactSearch"
                              @livesearchItemClick="requestContactChange"
                            />
                          </span>
                          <br>
                          <span class="contact-rfc text-muted">
                            {{ contact.rfc }}
                          </span>
                          <br>
                          <span class="contact-address"></span>
                          <br>
                          <span class="contact-email">
                            {{ contact.email }}
                          </span>
                          <br>
                          <span class="contact-phones">
                            {{ contact.phone }}
                          </span>
                          <br>
                        </address>
                        <small>
                          Moneda:
                          <!-- <a href="#" class="editable one-quotation-currency "> -->
                          {{ quotation.currency }}
                          <!-- </a> -->
                        </small>
                        <br>
                        <small>
                          <strong>
                            Condiciones de pago:
                          </strong>
                          <br>
                          <a href="#" class="editable one-quotation-payterms ">100% adelantado</a>
                        </small>
                      </td>
                      <td class="text-right">
                        <!-- <svg id="barcode"></svg> -->
                        <h1 class="one-quotation-id">
                          SO{{ quotation.quote_sequence }}
                        </h1>
                        <h2>
                          <LiveEdit
                            class="text-primary"
                            :value="quotation.title"
                            :show-buttons="false"
                            :enabled="isEditable"
                            @valueChanged="onCommonChanged('title', ...arguments)"
                          />
                        </h2>
                        <small>
                          <a
                            href="#" class="one-quotation-quotedate " data-type="combodate" data-value="2015-09-24" data-format="YYYY-MM-DD" data-viewformat="DD/MM/YYYY"
                            data-template="D / MMM / YYYY" data-pk="1" data-title="Select Date of birth"
                          >
                            Válido hasta: {{ quotefmtdate }}
                          </a>
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive">
                <table class="table table-condensed summary-table">
                  <thead>
                    <tr class="">
                      <td v-if="isEditable" class="table-toolbarrow  td-actions d-print-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1.08em" height="1em"
                          style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1920 1792"
                        >
                          <path
                            d="M896 896q0-106-75-181t-181-75t-181 75t-75 181t75 181t181 75t181-75t75-181zm768 512q0-52-38-90t-90-38t-90 38t-38 90q0 53 37.5 90.5t90.5 37.5t90.5-37.5t37.5-90.5zm0-1024q0-52-38-90t-90-38t-90 38t-38 90q0 53 37.5 90.5T1536 512t90.5-37.5T1664 384zm-384 421v185q0 10-7 19.5t-16 10.5l-155 24q-11 35-32 76q34 48 90 115q7 11 7 20q0 12-7 19q-23 30-82.5 89.5T999 1423q-11 0-21-7l-115-90q-37 19-77 31q-11 108-23 155q-7 24-30 24H547q-11 0-20-7.5t-10-17.5l-23-153q-34-10-75-31l-118 89q-7 7-20 7q-11 0-21-8q-144-133-144-160q0-9 7-19q10-14 41-53t47-61q-23-44-35-82l-152-24q-10-1-17-9.5T0 987V802q0-10 7-19.5T23 772l155-24q11-35 32-76q-34-48-90-115q-7-11-7-20q0-12 7-20q22-30 82-89t79-59q11 0 21 7l115 90q34-18 77-32q11-108 23-154q7-24 30-24h186q11 0 20 7.5t10 17.5l23 153q34 10 75 31l118-89q8-7 20-7q11 0 21 8q144 133 144 160q0 8-7 19q-12 16-42 54t-45 60q23 48 34 82l152 23q10 2 17 10.5t7 19.5zm640 533v140q0 16-149 31q-12 27-30 52q51 113 51 138q0 4-4 7q-122 71-124 71q-8 0-46-47t-52-68q-20 2-30 2t-30-2q-14 21-52 68t-46 47q-2 0-124-71q-4-3-4-7q0-25 51-138q-18-25-30-52q-149-15-149-31v-140q0-16 149-31q13-29 30-52q-51-113-51-138q0-4 4-7q4-2 35-20t59-34t30-16q8 0 46 46.5t52 67.5q20-2 30-2t30 2q51-71 92-112l6-2q4 0 124 70q4 3 4 7q0 25-51 138q17 23 30 52q149 15 149 31zm0-1024v140q0 16-149 31q-12 27-30 52q51 113 51 138q0 4-4 7q-122 71-124 71q-8 0-46-47t-52-68q-20 2-30 2t-30-2q-14 21-52 68t-46 47q-2 0-124-71q-4-3-4-7q0-25 51-138q-18-25-30-52q-149-15-149-31V314q0-16 149-31q13-29 30-52q-51-113-51-138q0-4 4-7q4-2 35-20t59-34t30-16q8 0 46 46.5t52 67.5q20-2 30-2t30 2q51-71 92-112l6-2q4 0 124 70q4 3 4 7q0 25-51 138q17 23 30 52q149 15 149 31z"
                            fill="white"
                          />
                        </svg>
                      </td>
                      <td class=" table-indexcol">
&nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="0.28em" height="1em"
                          style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 1408"
                        >
                          <path d="M384 1120v192q0 40-28 68t-68 28H96q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68zm0-512v192q0 40-28 68t-68 28H96q-40 0-68-28T0 800V608q0-40 28-68t68-28h192q40 0 68 28t28 68zm0-512v192q0 40-28 68t-68 28H96q-40 0-68-28T0 288V96q0-40 28-68T96 0h192q40 0 68 28t28 68z" fill="white" />
                        </svg>
                      </td>
                      <td width="99%">
                        <strong>Descripción</strong>
                      </td>
                      <td class="text-center">
                        <strong>Cant.</strong>
                      </td>
                      <td class="text-center">
                        <strong>Precio</strong>
                      </td>
                      <td class="text-center">
                        <strong>Desc (%)</strong>
                      </td>
                      <td class="text-right">
                        <strong>Total</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <transition-group name="quotes-list" tag="tr"> -->
                    <tr v-for="(q, idx) in quotes" :key="q._id" :class="coloClassForRow(idx)">
                      <td v-if="isEditable" class="btn-group td-draggable d-print-none">
                        <div class="btn-group">
                          <button
                            type="button" class="btn btn-xs btn-danger"
                            @click="removeQuoteAt(idx)"
                          >
                            <span class="iconify" data-icon="heroicons-outline:x" data-inline="false"></span>
                          </button>
                          <button
                            type="button" class="btn btn-xs btn-default" :class="{'disabled': idx < 1}"
                            @click="swapQuotes(idx, idx - 1)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
                              style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                            >
                              <g v-show="idx > 0" fill="none">
                                <path d="M5 11l7-7l7 7M5 19l7-7l7 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                            </svg>
                            <path d="M16 4l7 7l-1.4 1.4L16 6.8l-5.6 5.6L9 11z" fill="white" />
                          </button>
                          <button
                            type="button" class="btn btn-xs btn-default" :class="{'disabled': idx >= (quotes.length-1)}"
                            @click="swapQuotes(idx, idx + 1)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
                              style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                            >
                              <g v-show="idx < quotes.length-1" fill="none">
                                <path d="M19 13l-7 7l-7-7m14-8l-7 7l-7-7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                            </svg>
                          </button>
                          <div class="btn-group">
                            <button type="button" class="btn btn-xs btn-default dropdown-toggle" data-toggle="dropdown">
                              <svg
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
                                style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                              >
                                <g fill="none">
                                  <path d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 1 1 0-2a1 1 0 0 1 0 2zm0 7a1 1 0 1 1 0-2a1 1 0 0 1 0 2zm0 7a1 1 0 1 1 0-2a1 1 0 0 1 0 2z" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                              </svg>
                            </button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" href="#" @click="copyToClipboard($event, 1.0, idx)">Clonar</a>
                              <a class="dropdown-item" href="#" @click="copyToClipboard($event, 1.1, idx)">Clonar +10%</a>
                              <a class="dropdown-item" href="#" @click="copyToClipboard($event, 1.2, idx)">Clonar +20%</a>
                              <div class="dropdown-divider"></div>
                              <div class="drop-pallete" href="#">
                                <a class="item summary-row-default" href="#" @click.prevent="setQuoteColor(idx, 'default')">■</a>
                                <a class="item summary-row-block1" href="#" @click.prevent="setQuoteColor(idx, 'block1')">■</a>
                                <a class="item summary-row-block2" href="#" @click.prevent="setQuoteColor(idx, 'block2')">■</a>
                                <a class="item summary-row-info" href="#" @click.prevent="setQuoteColor(idx, 'info')">■</a>
                                <a class="item summary-row-warning" href="#" @click.prevent="setQuoteColor(idx, 'warning')">■</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="table-indexrow">
                        {{ idx+1 }}
                      </td>
                      <td class="table-descriptionrow">
                        <span class="quote-description preformat">
                          <LiveEditTextarea
                            class="text-primary"
                            :value="q.details"
                            :show-buttons="false"
                            :enabled="isEditable"
                            @valueChanged="setQuoteDetails(idx, ...arguments)"
                          />
                        </span>
                      </td>
                      <td class="table-quantityrow text-center">
                        <span class="quote-quantity">
                          <LiveEdit
                            class="text-primary"
                            :value="parseFloat(q.quantity).toFixed(2)"
                            :show-buttons="false"
                            :enabled="isEditable"
                            @valueChanged="setQuoteField(idx, 'quantity', ...arguments)"
                          />
                        </span>
                      </td>
                      <td class="table-amountrow text-center ">
                        <span class="quote-amount text-nowrap">
                          <LiveEdit
                            class="text-primary"
                            :value="parseFloat(q.amount).toFixed(2)"
                            :show-buttons="false"
                            :enabled="isEditable"
                            @valueChanged="setQuoteField(idx, 'amount', ...arguments)"
                          />
                        </span>
                      </td>
                      <td class="table-discountrow text-center">
                        <span class="quote-discount">
                          <LiveEdit
                            class="text-primary"
                            :value="parseFloat(q.discount).toFixed(1)"
                            :show-buttons="false"
                            :enabled="isEditable"
                            @valueChanged="setQuoteField(idx, 'discount', ...arguments)"
                          />
                        </span>
                      </td>
                      <td class="table-subtotalrow text-right">
                        <span class="quote-subtotal">{{ formatThousands(subtotalFor(idx).toFixed(2) ) }}</span>
                      </td>
                    </tr>
                    <!-- </transition-group> -->
                  </tbody>
                  <tfoot>
                    <!-- LINEAS FINALES DE COTIZACION, SUMA TOTAL-->
                    <tr class="no-line ">
                      <td v-if="isEditable" class="quoterow_toolbar no-line d-print-none flex-shrink-0">
                        <div class="btn-group">
                          <button type="button" class="btn btn-sm btn-default btn-flsat" @click="addquote()">
                            Agregar
                          </button>
                          <button type="button" class="btn btn-sm btn-default btn-flast dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                            <span class="caret"></span>
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(72px, 29px, 0px); top: 0px; left: 0px; will-change: transform;">
                            <a class="dropdown-item" href="#" @click="addquote('CAP')">CAP: Capacitación</a>
                            <a class="dropdown-item" href="#" @click="addquote('VIA')">VIA: Viáticos</a>
                            <a class="dropdown-item" href="#" @click="addquote('PLA')">LFX: Player</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">
                              Buscar...
                              <svg
                                class="float-right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em"
                                height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                              >
                                <g fill="none">
                                  <path d="M21 21l-6-6m2-5a7 7 0 1 1-14 0a7 7 0 0 1 14 0z" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                              </svg>
                            </a>
                          </div>
                        </div>
                        <!-- <div class="dropdown toolbar-edit-controls">
                          <button class="btn btn-primary btn-sm dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-fw fa-plus" aria-hidden="true"></i>
                            Agregar
                          </button>
                          <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
                            <li class="dropdown-item add-quoteline">
                              <a href="#">
                                <i class="fa fa-fw fa-plus" aria-hidden="true"></i>
                                Línea vacía</a>
                            </li>
                            <li class="dropdown-item add-search-product" data-toggle="modal" data-target="#modal-product-list">
                              <a href="#">
                                <i class="fa fa-fw fa-search-plus" aria-hidden="true"></i>
                                Buscar producto...
                              </a>
                            </li>
                            <h6 class="dropdown-header">Agregar rápido </h6>
                            <li class="dropdown-item add-quote-cap">
                              <a href="#">
                                CAP: Capacitación inicial
                              </a>
                            </li>
                            <li class="dropdown-item add-quote-via">
                              <a href="#">
                                VIA: Viáticos y hospedaje
                              </a>
                            </li>
                            <li class="dropdown-divider"></li>
                            <li class="dropdown-submenu">
                              <a class="dropdown-item" tabindex="-1" href="#">Comisión calculada</a>
                              <ul class="dropdown-menu">
                                <li class="dropdown-item">
                                  <a tabindex="-1" class="toolrow-commission" data-percen="5" href="#">5%</a>
                                </li>
                                <li class="dropdown-item">
                                  <a href="#" class="toolrow-commission" data-percen="10">10%</a>
                                </li>
                                <li class="dropdown-item">
                                  <a href="#" class="toolrow-commission" data-percen="15">15%</a>
                                </li>
                                <li class="dropdown-item">
                                  <a href="#" class="toolrow-commission" data-percen="20">20%</a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div> -->
                      </td>
                      <td class="thick-line"></td>
                      <td class="thick-line"></td>
                      <td class="thick-line"></td>
                      <td class="thick-line"></td>
                      <td class="thick-line text-right">
                        <strong>Subtotal</strong>
                      </td>
                      <td class="thick-line text-right text-nowrap quote-net-subtotal">
                        {{ formatThousands( subtotal.toFixed(2)) }}
                      </td>
                    </tr>
                    <tr class="no-line">
                      <td v-if="isEditable" class="quoterow_toolbar d-print-none no-line"></td>
                      <td class="no-line"></td>
                      <td class="no-line"></td>
                      <td class="no-line"></td>
                      <td class="no-line"></td>
                      <td class="no-line text-right">
                        <strong>Impuestos</strong>
                      </td>
                      <td class="no-line text-right text-nowrap quote-net-taxes">
                        {{ formatThousands(tax.toFixed(2)) }}
                      </td>
                    </tr>
                    <tr class="no-line">
                      <td v-if="isEditable" class="quoterow_toolbar d-print-none no-line"></td>
                      <td class="thick-line text-right" colspan="5">
                        <strong>Total</strong><br>
                        <small class="quote-net-total-words">
                          ({{ writtenTotal }})
                        </small>
                      </td>
                      <td class="thick-line text-right text-nowrap quote-net-total">
                        $&nbsp;{{ formatThousands((subtotal + tax).toFixed(2) ) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div v-show="unsaved" class="alert alert-warnisng text-warning border-warning flexbox d-print-none">
                  <div class="justify-content-start">
                    Hay cambios sin guardar
                  </div>
                  <button type="button" class="btn btn-xs btn-warning justify-content-end" @click="saveChanges">
                    Guardar
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-11">
                  <p>
                    <strong>Notas:</strong>
                  </p>
                  <small>
                    <a id="one-quotation-comments" class="preformateditable-pre-wrapped  editable-empty" href="#" data-type="textarea">
                      <LiveEditTextarea
                        class="text-primary"
                        :value="quotation.comments"
                        :show-buttons="false"
                        :enabled="isEditable"
                        empty="Ninguna."
                        @valueChanged="onCommonChanged('comments', ...arguments)"
                      />
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="profile2" class="tab-pane pad" role="tabpanel" aria-expanded="false">
          2
        </div>
        <div id="messages2" class="tab-pane pad" role="tabpanel" aria-expanded="false">
          3
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import { format } from "date-fns";
import esmx from "date-fns/locale/es";
import LiveEdit from "@/components/LiveEdit.vue";
import LiveEditTextarea from "@/components/LiveEditTextarea.vue";
import LiveSearch from "@/components/LiveSearch.vue";

// import ReconsLogsTable from "@/components/rocket/RktReconLogsTable.vue";
import ContactsApi from "@/plugins/Contacts/Contacts.service";
import UsersApi from "@/services/Users.service";
import QuotationsApi from "./Quotations.service";

const base64 = require("base-64");
const utf8 = require("utf8");
const writtenNumber = require("written-number");

writtenNumber.defaults.lang = "es";

// writtenNumber(1234); // => 'one thousand two hundred and thirty-four'

writtenNumber.defaults.lang = "es";

// const formatDate = require("date-fns/format");
// const parseDate = require("date-fns/parse");

export default {
  components: {
    LiveEdit,
    LiveSearch,
    LiveEditTextarea,
    Icon
  },

  props: {
    quotation: {
      type: Object,
      default() {
        return {
          name: "<desconocido>",
          phone: "",
          email: "",
          job: "",
          web: "",
          users: []
        };
      }
    }
  },
  data() {
    return {
      contactsUrl: ContactsApi.baseurl(),
      unsaved: false,
      isfullscreen: false,
      searching: false,
      searchresults: [], // {id, label, obj}
      availableUsersToShare: [],
      livequotation: {
        quotes: []
      },
      pasteText: ""
    };
  },
  computed: {
    ...mapState(["user"]),
    contact() {
      const { contact = {} } = this.livequotation;
      const {
        name = "", email = "", phone = "", rfc = ""
      } = contact;
      return {
        name,
        email,
        phone,
        rfc
      };
    },
    isEditable() {
      const { status = "open" } = this.livequotation;
      return status === "open";
    },
    quotefmtdate() {
      const d = new Date(this.quotation.validuntil);
      const date = format(d, "d LLL, yyyy",  { locale: esmx } );
      return date;
    },
    subtotal() {
      const { quotes = [] } = this.livequotation;
      return quotes.reduce((acc, curr) => {
        const { amount = 0.0 } = curr;
        const { quantity = 0.0 } = curr;
        const { discount = 0.0 } = curr;
        return (
          acc
          + parseFloat(amount)
            * parseFloat(quantity)
            * (1.0 - 0.01 * parseFloat(discount))
        );
      }, 0.0);
    },
    tax() {
      return this.subtotal * 0.16;
    },
    quotes() {
      let { quotes = [] } = this.livequotation;
      quotes = quotes.filter(q => q); // Eliminar null
      return quotes;
    },
    writtenTotal() {
      const names = {
        MXN: "pesos",
        USD: "dólares",
        EUR: "euros"
      };
      const { currency } = this.livequotation;
      const total = parseInt((this.subtotal + this.tax).toFixed(2), 10);
      const cents = (
        this.getDecimalPart(this.subtotal + this.tax) * 100
      ).toFixed(0);
      const currencyName = names[currency];
      return `${writtenNumber(
        total.toFixed(0)
      )} ${currencyName} ${cents}/100, ${currency}`;
    },
    sharedUsers() {
      // // Usuarios con acceso permitido en este momento
      const { users: currentUsers = [] } = this.quotation ?? {};
      // console.log("USErs", currentUsers, this.availableUsersToShare);
      const currentUserIds = currentUsers.map(it => it._id);
      return this.availableUsersToShare.map(it => {
        const { id, email, name } = it;
        // console.log("SH", id, currentUsers);
        const sharing = currentUserIds.indexOf(id) > -1;
        return {
          id,
          email,
          name,
          sharing
        };
      });
    },
    shareCount() {
      const sharing = this.sharedUsers.filter(it => it.sharing);
      return sharing.length;
    },
    statusTitle() {
      const { status } = this.livequotation;
      const title = {
        open: "Editable",
        locked: "Bloqueada",
        accepted: "Aceptada",
        trash: "Cancelada",
        default: "Status",
      };
      return title[status] ?? title.default;
    },
    statusIcons() {
      const { status } = this.livequotation;
      const icon2status = {
        open: "carbon:edit",
        locked: "ant-design:lock-filled",
        accepted: "mdi:thumbs-up",
        trash: "tabler:trash",
        default: "ph:dots-three-bold",
      };
      return icon2status[status] ?? icon2status.default;
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    quotation(val, oldval) {
      // console.log("quotes watcher", val.quotes.length, oldval.quotes);
      // eslint-disable-next-line prefer-const
      let { quotes = [], quote_sequence: quoteSeq = 0 } = val ?? {};
      quotes = quotes.filter(x => !!x); // filtrar nulos
      const sane = val;
      sane.quotes = quotes;
      const {
        quotes: livequotes = [], quote_sequence: quoteSeqLive = -1, comments = "", title = ""
      } = this.livequotation ?? {};
      if (this.unsaved && quoteSeq === quoteSeqLive) { // Mantenemos los cambios sin guardar
        sane.quotes = livequotes;
        sane.comments = comments;
        sane.title = title;
        this.livequotation = sane;
        this.unsaved = true;
        return;
      }

      this.livequotation = sane;
      this.unsaved = false;
    },
    // eslint-disable-next-line no-unused-vars
    unsaved(val, old) {
      this.$emit("quotationUnsaved", val);
    }
  },

  methods: {
    getUsersShareList() {
      UsersApi.getUserList().then(res => {
        // console.log(res.data);
        const { total = 0, rows = [] } = res.data;
        if (total < 1) {
          console.warn(
            "WARNING: No users on userlist or total mismatch, this is a security BUG! STOP NOW!"
          );
          return;
        }
        if (rows.length !== total) {
          console.warn(
            "WARNING: Total user count and rows total mismatch, this is a security BUG!"
          );
        }
        const { _id: myid } = this.user;
        const userlist = rows
          .filter(it => it._id !== myid) // ignorarse a si mismo
          .map(it => {
            const { _id, email, name } = it;
            // console.log("SH", _id, currentUsers);
            return {
              id: _id,
              email,
              name
            };
          });
        this.availableUsersToShare = userlist;
      });
    },
    toggleSharing(user) {
      // contiene el actual status de sharing y hay que invertirlo
      // console.log("Toggle sharing of:", user);
      const { email = "" } = user ?? {};
      const { _id = null } = this.quotation;
      const vm = this;
      QuotationsApi.toggleSharing(_id, user).then(() => {
        vm.$emit("sharing-modified", user);
        this.$emit("dbUpdated");
      })
        .catch((e) => {
          console.log(e.response);
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          this.messageAlert({
            icon: "error",
            title: `No se pudo compartir con <strong>${email}</strong>`,
            text: `${errStr}, CODE: ${status}`
          });
        });
    },
    getDecimalPart(decNum) {
      // https://stackoverflow.com/a/65046431/2573334
      return Math.round((decNum % 1) * 100000000) / 100000000;
    },
    formatThousands(num) {
      // Separar miles
      const formatted = String(num).replace(/^\d+/, number => [...number]
        .map(
          (digit, index, digits) => (!index || (digits.length - index) % 3 ? "" : ",") + digit
        )
        .join(""));
      return formatted;
    },
    coloClassForRow(idx) {
      const { color } = this.quotes[idx];
      const color2class = {
        default: "",
        block1: "summary-row-block1",
        block2: "summary-row-block2",
        info: "summary-row-info",
        warning: "summary-row-warning"
      };
      if (Object.keys(color2class).indexOf(color) < 0) {
        return "";
      }
      return color2class[color];
    },
    onCommonChanged(field, value) {
      // console.log("FieldVal", field, value);
      this.$set(this.livequotation, field, value);
      this.unsaved = true;
    },
    onContactSearch(val) {
      // console.log("changed", val);
      if (!this.searching) {
        this.searching = true;
        ContactsApi.search({ search: val })
          .then(res => {
            this.searching = false;
            const { rows = [] } = res;
            const results = rows.map(it => ({
              id: it._id,
              label: it.name,
              item: it
            }));
            this.searchresults = results;
          })
          .catch(err => {
            this.searching = false;
            console.log("Error in search", err);
          });
      }
    },
    // eslint-disable-next-line no-unused-vars
    requestContactChange(contactid, contact) {
      // console.log("Request contact swap to", contactid, contact);
      this.searchresults = [];
      this.searching = false;
      const { _id: quoteId } = this.quotation ?? {};
      QuotationsApi.updateById(quoteId, { contact: contactid })
        .then(res => {
          const { contact: resContact = {} } = res.data ?? {};
          this.$set(this.livequotation, "contact", resContact);
          this.$emit("dbUpdated", res.data);
        })
        .catch(() => {
          // console.warn("Update err", err);
        });
    },
    // eslint-disable-next-line no-unused-vars
    addquote(quickkey) {
      const q = {
        details: "Desarrollo de software",
        quantity: 1.0,
        units: "hrs",
        amount: 1.0,
        discount: 0.0
      };
      const { quotes = [] } = this.livequotation;
      quotes.push(q);
      this.$set(this.livequotation, "quotes", quotes);
      this.unsaved = true;
    },
    removeQuoteAt(idx) {
      this.livequotation.quotes.splice(idx, 1);
      this.unsaved = true;
    },
    swapQuotes(idx1, idx2) {
      if (
        idx1 < 0
        || idx2 < 0
        || idx1 === idx2
        || idx1 >= this.quotes.length
        || idx2 >= this.quotes.length
      ) {
        // console.log("Ignore Swap", idx1, idx2);
        return;
      }

      const q = this.quotes;
      const tmp = q[idx1];
      q[idx1] = q[idx2];
      q[idx2] = tmp;
      this.$set(this.livequotation, "quotes", q);
      this.unsaved = true;
    },
    setQuoteField(idx, field, value) {
      // Casualmente todos los campos son floats
      const newval = parseFloat(value.replace(/,/g, "")).toString(); // ignorar comas (,), guardar como String

      console.log("Setting", idx, field, newval);
      const item = this.quotes[idx];
      item[field] = newval;
      // Para que reaccione se requiere $set
      this.$set(this.quotes, idx, item);
      this.unsaved = true;
    },
    setQuoteColor(idx, color) {
      console.log("Setting color for", idx, color);
      // Para que reaccione se requiere $set
      const item = this.quotes[idx];
      item.color = color;
      this.$set(this.quotes, idx, item);
      this.unsaved = true;
    },
    setQuoteDetails(idx, value) {
      // Casualmente todos los campos son floats
      const newval = value.trim();
      console.log("Setting", idx, newval);
      const isSOLinkReg = /^@SO[\d]+\b$/gm; // Checa si es un SOnnnn solito en la linea, que seria un fetch a otro SO, se lo trae sin IVA
      if (isSOLinkReg.test(newval)) {
        const seq = newval.slice(3); // le tumba el #SO
        console.log("Es un link de SOnnn", newval, seq);

        QuotationsApi.findOneBySeq(seq).then(res => {
          console.log("*", res);
          if (!res) {
            return;
          }
          let { quotes = [] } = res;
          quotes = quotes.filter(q => q); // Eliminar null
          const subtotal = quotes.reduce((acc, curr) => {
            const { amount = 0.0 } = curr;
            const { quantity = 0.0 } = curr;
            const { discount = 0.0 } = curr;
            return (
              acc
              + parseFloat(amount)
                * parseFloat(quantity)
                * (1.0 - 0.01 * parseFloat(discount))
            );
          }, 0.0);
          console.log("Es un link de SOnnn y el subtotal", subtotal);
          this.setQuoteField(idx, "amount", subtotal.toString());
          this.setQuoteDetails(idx, `→ ${newval.slice(1)}:\n${res.title}`); // OJO SI Res title es #SO4444
        });
      }
      else {
        const item = this.quotes[idx];
        item.details = newval;
        // Para que reaccione se requiere $set
        this.$set(this.quotes, idx, item);
      }

      this.unsaved = true;
    },
    subtotalFor(idx) {
      const { quotes = [] } = this.livequotation;
      if (idx < 0 || idx > quotes.length - 1) {
        return 0;
      }
      const item = quotes[idx];
      return (
        parseFloat(item.quantity)
        * parseFloat(item.amount)
        * (1.0 - 0.01 * parseFloat(item.discount))
      );
    },
    saveChanges() {
      return new Promise((resolve, reject) => {
        let { quotes } = this.livequotation;
        const { comments, title } = this.livequotation;
        quotes = quotes.filter(x => !!x);
        QuotationsApi.updateById(this.quotation._id, {
          quotes,
          comments,
          title
        })
          .then(res => {
            this.$set(this.quotation, res.data);
            this.unsaved = false;
            resolve(res);
            this.$emit("dbUpdated"); // inform parent
            this.messageAlert({
              icon: "success",
              title: "Guardado",
              text: `SO${res.data.quote_sequence} se guardó!`,
            });
          })
          .catch(err => {
            console.warn("Update err", err);
            reject(err);
          });
      });
    },
    generatePdf() {
      // console.log(this.$refs.printContainer.outerHTML);
      const bytes = utf8.encode(this.$refs.printContainer.outerHTML);
      const base64htm = base64.encode(bytes);

      const q = { ...this.quotation, base64htm };
      this.saveChanges()
        // eslint-disable-next-line no-unused-vars
        .then(res => QuotationsApi.createPdf(q))
        .then(res => {
          // PDF created
          // console.log("PDF", res);
          const { _signedurl = null } = res;
          if (_signedurl) {
            window.open(_signedurl, `${this.quotation._id}`);
            this.$emit("pdf-created", _signedurl);
          }
        })
        .catch(() => {});
    },
    setQuotationStatus(stat) {
      console.log("Setting status", stat);
      const statList = ["open", "locked", "trash", "accepted"];
      let status = stat;
      if (statList.indexOf(stat) < 0) {
        status = "open";
      }

      QuotationsApi.updateById(this.quotation._id, { status })
        .then(res => {
          // Para que reaccione se requiere $set
          const { status: resStatus = "open" } = res.data ?? {};
          this.$set(this.livequotation, "status", resStatus);
          this.$emit("dbUpdated", res.data);
        })
        .catch(() => {
          // console.warn("Update err", err);
        });
    },
    copyToClipboard(ev, copyFactor, index) {
      ev.preventDefault();

      const { comments = [], title = "" } = this.quotation;
      let { quotes = [] } = this.quotation;
      quotes = quotes.map((quote, idx) => {
        let {
          amount = "0"
        } = quote;
        const {
          color = "",
          details = "",
          discount = "0",
          quantity = "0",
          units = ""
        } = quote;
        amount = parseFloat(amount);
        amount = Number.isNaN(amount) ? 0 : amount;
        amount = (amount * copyFactor).toFixed(2);
        if (index > -1 && index !== idx) {
          return null;
        }
        return {
          amount,
          color,
          details,
          discount,
          quantity,
          units
        };
      }).filter(Boolean);

      const quoteJson = {
        comments,
        quotes,
        title
      };
      const json = JSON.stringify(quoteJson);
      navigator.clipboard.writeText(json);

      this.messageAlert({
        icon: "success",
        title: "Renglones de cotización copiadas al portapapeles",
      });
    },
    addPastedQuote(q) {
      const { quotes = [] } = this.livequotation;
      const {
        quotes: quotesPasted = [] // comments = [], title = ""
      } = q ?? {};
      quotes.push(...quotesPasted);
      this.$set(this.livequotation, "quotes", quotes);
      this.unsaved = true;

      this.messageAlert({
        icon: "success",
        title: "Renglones de cotización agregadas",
      });
    },
    onPaste(ev) {
      ev.stopPropagation();
      ev.preventDefault();

      const { items } = ev.clipboardData || ev.originalEvent.clipboardData;
      // eslint-disable-next-line guard-for-in
      for (const index in items) {
        const item = items[index];
        if (item.kind === "string") {
          const text = ev.clipboardData.getData("Text");
          this.pasteText = text;
        }
      }
      // Validamos el JSON
      const quote = this.validateJson({ data: this.pasteText });
      const { quotes = [] } = quote ?? {};
      if (!Array.isArray(quotes) || quotes.length < 1) {
        this.messageAlert({
          icon: "error",
          title: "Renglones de cotización inválidas",
        });
        return;
      }
      // Nos aseguramos que cada entrada tenga datos default
      const quoterows = quotes.map(item => {
        const {
          amount = 0,
          color = "",
          details = "",
          discount = 0,
          quantity = 0,
          units = ""
        } = item ?? {};
        return {
          amount,
          color,
          details,
          discount,
          quantity,
          units
        };
      });
      quote.quotes = quoterows;
      this.addPastedQuote(quote);
    },
    validateJson(jsonString) {
      try {
        const { data = null } = jsonString ?? {};
        if (typeof data !== "string") {
          console.log("JSON not valid, JSON must be a string to parse");
          return null;
        }
        if (!data || data === "{}") {
          console.log("JSON not valid, empty data");
          return null;
        }

        const json = JSON.parse(data);
        return json;
      }
      catch (error) {
        console.log(`JSON not valid, ${error}`);
        return null;
      }
    },
    messageAlert(props) {
      const {
        position = "bottom-end",
        toast = true,
        icon = "info",
        title = "sin definir",
        text = "",
        showConfirmButton = false,
        timer = 2500,
        timerProgressBar = true
      } = props ?? {};
      this.$swal({
        position,
        toast,
        icon,
        title,
        text,
        showConfirmButton,
        timer,
        timerProgressBar
      });
    },
    shortcutHandler(ev) {
      if (ev.keyCode === 83 && ev.ctrlKey) {
        ev.preventDefault();
        // eslint-disable-next-line no-unused-vars
        this.saveChanges().then(res => {});
      }
      if (ev.keyCode === 80 && ev.ctrlKey) {
        ev.preventDefault();
        this.generatePdf();
      }
      if (ev.keyCode === 76 && ev.ctrlKey && ev.shiftKey) {
        ev.preventDefault();
        console.log("LOCK, TODO: No se si sea valioso poner este sortcut.");
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", this.shortcutHandler);
    this.getUsersShareList();
    this.$on("sharing-modified", (user) => {
      const { email = "Desconocido", sharing = false } = user;
      const shared = !sharing; // cuando se llama evento ya realizo los cambios
      let msg = `Se ha compartido con ${email}`;
      if (!shared) {
        msg = `Se ha dejado de compartir con ${email}`;
      }
      this.messageAlert({
        icon: "success",
        title: msg,
      });
    });
  },
  beforeMount() {
    // La primera vez, luego aplica el watcher
    // this.livequotation = this.quotation;
    let { quotes = [] } = this.quotation;
    quotes = quotes.filter(x => !!x); // filtrar nulos
    const sane = this.quotation;
    sane.quotes = quotes;
    this.livequotation = sane; // la live mantiene el buffer temporal
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.shortcutHandler);
  },
};
</script>
