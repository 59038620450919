<style scoped>
/* @import "~simplemde/dist/simplemde.min.css"; */

.dotted-text {
  text-decoration: underline dashed #33cabb88;
  color: unset;
}
.preformat{
  white-space: pre-wrap;
}
textarea {
  resize: both;
}
</style>
<template>
  <div class="live-edit-container">
    <!-- <slot v-if="!this.editing"></slot> -->
    <a
      v-show="!editing"
      href="#" class="hover-text-info preformat"
      :class="{'text-danger': (v.length<1), 'dotted-text': enabled}"
      :style="{'pointer-events': (enabled ? 'auto':'none')}"
      @click.prevent="toggleEdit"
    >
      <span v-if="v.length>0" v-html="enhancedText"></span>
      <span v-else>{{ empty }} </span>
    </a>
    <div v-show="editing" class="controls">
      <textarea
        ref="livefield"
        v-model="v"
        class="form-control"
        @keydown.esc="onCancel"
        @blur="onAccepted"
        @keydown.enter="onEnter"
      ></textarea>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
// import VueSimplemde from "vue-simplemde";

export default {
  components: {
    // VueSimplemde
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    enabled: {
      type: Boolean,
      default: true
    },
    hint: {
      type: String,
      default: ""
    },
    empty: { type: String, default: "(sin llenar)" },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 30 },
    showButtons: {
      type: Boolean,
      default: true
    },

    livesearch: {
      type: Boolean,
      default: false
    },
    livesearchValues: {
      type: Array,
      default: () => [] // array de {id, label, item}
    }
  },
  data() {
    return {
      editing: false,
      v: "",
      old: "",
      error: false,
      mdeconfig: {
        status: false, // disable the status bar at the bottom
        spellChecker: false, // disable spell check
      },
      mde: {}
    };
  },
  computed: {
    enhancedText() {
      const html = this.v
        // .replace(/^### (.*$)/gim, "<h3>$1</h3>")
        .replace(/^## (.*$)/gim, "<h2>$1</h2>")
        // .replace(/^# (.*$)/gim, "<h1>$1</h1>")
        // .replace(/^\> (.*$)/gim, "<blockquote>$1</blockquote>")
        .replace(/\*\*(.*)\*\*/gim, "<strong>$1</strong>")
        .replace(/__(.*?)__/gm, "<span style='text-decoration: underline;'>$1</span>")
        .replace(/_(.*)_/gim, "<i>$1</i>")
        // .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
        // .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
        .replace(/^ \* (.+?)(\n|$)/gm, "<li>$1</li>")
        .replace(/(<li>.+<\/li>)/igm, "<ul>$1</ul>")
        .replace(/\n$/gim, "<br />");
        // .trim();

      return html;
    }
  },
  watch: {
    v: {
      handler() {
        this.vWatcher(this.v);
        // Solo cuando esta en modo edición, evita que se lance al montar/crear
        if (this.livesearch && this.v.length > 2 && this.editing) {
          this.$emit("searchValueChanged", this.v);
        }
      }
    },
    value(val, old) {
      // console.log("·Value changed", old, ">", val);
      Vue.nextTick(() => {
        // DOM updated
        this.v = val;
        this.old = old;
      });
    }
  },
  methods: {
    vWatcher(_v) {
      // console.log("watcher of v in edit");
      this.v = typeof this.v !== "undefined" ? this.v.trimLeft() : "";
    },
    isValid() {
      if (!this.v || this.v.length < this.min) {
        return false;
      }
      if (this.v.length > this.max) {
        return false;
      }
      return true;
    },
    toggleEdit() {
      if (!this.enabled) {
        this.onCancel();
        return;
      }
      this.editing = !this.editing;
      if (this.editing) {
        this.old = this.v; // save old value to allow cancel
        const self = this;
        Vue.nextTick(() => {
          // No se puede directo porque se acaba de crear
          self.$refs.livefield.focus();
        });
      }
      this.$emit("editingChanged", this.editing);
    },
    onAccepted() {
      if (this.error) {
        return;
      }
      this.editing = false;
      this.v = this.v.trim();
      this.$emit("valueChanged", this.v, this.old);
    },
    onCancel(reset = true) {
      this.v = this.old;
      if (reset) {
        this.editing = false;
      }
    },
    onLiveItemClick(it) {
      console.log(it);
      this.$emit("livesearchItemClick", it.id, it.item);
      this.editing = false;
    },
    onEnter(ev) {
      // console.log(ev);
      if (ev.ctrlKey) {
        this.onAccepted();
      }
    }
  },
  mounted() {
    // this.mde = new SimpleMDE({ element: this.$refs.livefield });
    // console.log(this.mde);
    // this.mde.value(this.v);
  },
  beforeMount() {
    this.v = this.value;
    this.old = this.value;
  },

};
</script>
